export const MediaType: { [key: string]: string } = {
  anime: "ANIME",
  manga: "MANGA",
};

export const MediaSeason: { [key: string]: string } = {
  winter: "WINTER",
  spring: "SPRING",
  summer: "SUMMER",
  fall: "FALL",
};

export const MediaSort: { [key: string]: string } = {
  id: "ID",
  idDesc: "ID_DESC",
  titleRomanji: "TITLE_ROMAJI",
  titleRomanjiDesc: "TITLE_ROMAJI_DESC",
  titleEnglish: "TITLE_ENGLISH",
  titleEnglishDesc: "TITLE_ENGLISH_DESC",
  titleNative: "TITLE_NATIVE",
  titleNativeDesc: "TITLE_NATIVE_DESC",
  type: "TYPE",
  typeDesc: "TYPE_DESC",
  format: "FORMAT",
  formatDesc: "FORMAT_DESC",
  startDate: "START_DATE",
  startDateDesc: "START_DATE_DESC",
  endDate: "END_DATE",
  endDateDesc: "END_DATE_DESC",
  score: "SCORE",
  scoreDesc: "SCORE_DESC",
  popularity: "POPULARITY",
  popularityDesc: "POPULARITY_DESC",
  trending: "TRENDING",
  trendingDesc: "TRENDING_DESC",
  episodes: "EPISODES",
  episodesDesc: "EPISODES_DESC",
  duration: "DURATION",
  durationDesc: "DURATION_DESC",
  status: "STATUS",
  statusDesc: "STATUS_DESC",
  chapters: "CHAPTERS",
  chaptersDesc: "CHAPTERS_DESC",
  volumes: "VOLUMES",
  volumesDesc: "VOLUMES_DESC",
  updatedAt: "UPDATED_AT",
  updatedAtDesc: "UPDATED_AT_DESC",
  searchMatch: "SEARCH_MATCH",
  favourites: "FAVOURITES",
  favouritesDesc: "FAVOURITES_DESC",
};

export const MediaSortString: { [key: string]: string } = {
  id: "Id",
  idDesc: "Id",
  titleRomanji: "Title",
  titleRomanjiDesc: "Title",
  titleEnglish: "Title",
  titleEnglishDesc: "Title",
  titleNative: "Title",
  titleNativeDesc: "Title",
  type: "Type",
  typeDesc: "Type",
  format: "Format",
  formatDesc: "Format",
  startDate: "Newest",
  startDateDesc: "Newest",
  endDate: "Oldest",
  endDateDesc: "Oldest",
  score: "Score",
  scoreDesc: "Score",
  popularity: "Popularity",
  popularityDesc: "Popularity",
  trending: "Trending",
  trendingDesc: "Trending",
  episodes: "Episodes",
  episodesDesc: "Episodes",
  duration: "Duration",
  durationDesc: "Duration",
  status: "Status",
  statusDesc: "Status",
  chapters: "Chapters",
  chaptersDesc: "Chapters",
  volumes: "Volumes",
  volumesDesc: "Volumes",
  updatedAt: "Updated",
  updatedAtDesc: "Updated",
  searchMatch: "Search Match",
  favourites: "Favorites",
  favouritesDesc: "Favorites",
};

export const CharacterSort: { [key: string]: string } = {
  id: "ID",
  idDesc: "ID_DESC",
  role: "ROLE",
  roleDesc: "ROLE_DESC",
  searchMatch: "SEARCH_MATCH",
  favourites: "FAVOURITES",
  favouritesDesc: "FAVOURITES_DESC",
  relevance: "RELEVANCE",
};

export const StaffSort: { [key: string]: string } = {
  id: "ID",
  idDesc: "ID_DESC",
  language: "LANGUAGE",
  languageDesc: "LANGUAGE_DESC",
  searchMatch: "SEARCH_MATCH",
  favourites: "FAVOURITES",
  favouritesDesc: "FAVOURITES_DESC",
  relevance: "RELEVANCE",
};

export const MediaFormat: { [key: string]: string } = {
  tv: "TV",
  tvShort: "TV_SHORT",
  movie: "MOVIE",
  special: "SPECIAL",
  ova: "OVA",
  ona: "ONA",
  music: "MUSIC",
  manga: "MANGA",
  novel: "NOVEL",
  oneShot: "ONE_SHOT",
};

export const MediaRank: { [key: string]: string } = {
  rated: "RATED",
  popular: "POPULAR",
};
